import { Pipe, PipeTransform } from '@angular/core';
import { PercentPipe } from '@angular/common';

@Pipe({
  name: 'pwPercent',
  standalone: true,
})
export class PwPercentPipe implements PipeTransform {
  transform(value: number): unknown {
    return new PercentPipe('en-US').transform(value, '1.0-2');
  }
}
