import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  output,
} from '@angular/core';
import {
  getItemScheduleTreeNodeChildren,
  ItemScheduleTreeNode,
} from '@pwiz/sprint/ts';
import { ItemIdComponent } from '@pwiz/item-common/ui';
import { TicketIdComponent } from '@pwiz/ticket/ui';
import { PwSprintTicketEffortPipe, SprintNamePipe } from '../../../pipe';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatTooltip } from '@angular/material/tooltip';
import {
  ClickOnInitDirective,
  FixedPipe,
  PwEnumToReadableStringPipe,
  RotateDirective,
  useHostBinding,
} from '@pwiz/infra/ui';
import { isIssueActive, isTicketDone } from '@pwiz/ticket/ts';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatTreeNodeToggle } from '@angular/material/tree';
import { ITeam } from '@pwiz/entity/ts';

@Component({
  selector: 'pw-item-sprint-schedule-node',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ItemIdComponent,
    TicketIdComponent,
    SprintNamePipe,
    MatCheckbox,
    MatTooltip,
    PwEnumToReadableStringPipe,
    ClickOnInitDirective,
    MatIcon,
    MatIconButton,
    MatTreeNodeToggle,
    RotateDirective,
    FixedPipe,
    PwSprintTicketEffortPipe,
  ],
  host: {
    class: 'pw-item-sprint-schedule-node small-icon-button',
  },
  templateUrl: 'item-sprint-schedule-node.component.html',
  styleUrl: 'item-sprint-schedule-node.component.scss',
})
export class ItemSprintScheduleNodeComponent {
  node = input.required<ItemScheduleTreeNode>();
  selectedTicketIdList = input.required<string[]>();
  isExpanded = input(false, { transform: booleanAttribute });
  isExpandable = input(false, { transform: booleanAttribute });
  noSelect = input(false, { transform: booleanAttribute });
  team = input.required<ITeam | null>();

  selected = output<{ node: ItemScheduleTreeNode; checked: boolean }>();

  $isDisabled = computed(() => {
    return this.isDisabled(this.node());
  });

  $isDone = computed(() => this.isDone(this.node()));

  #$isNodeSelected = computed(() => {
    if (this.noSelect()) {
      return false;
    }
    const idList = this.selectedTicketIdList();
    const id = this.node().issue.id;
    return idList.includes(id);
  });

  #$childNodes = computed(() => getItemScheduleTreeNodeChildren(this.node()));

  $isIndeterminate = computed(() => {
    if (this.noSelect() || this.#$isNodeSelected() || this.$isChecked()) {
      return false;
    }
    return this.#$childNodes()?.some((node) => this.isNodeSelected(node));
  });

  $isChecked = computed(() => {
    if (this.noSelect()) {
      return false;
    }
    if (this.isDisabled(this.node())) {
      return false;
    }
    if (this.#$isNodeSelected()) {
      return true;
    }
    return this.#$childNodes()?.every((node) => {
      if (this.isDisabled(node)) {
        return true;
      }
      return this.isNodeSelected(node);
    });
  });

  constructor() {
    const { conditionalClass } = useHostBinding();
    effect(() => conditionalClass('select-tickets', !this.noSelect()));
    effect(() => conditionalClass('done', this.$isDone()));
    effect(() => conditionalClass('disabled', this.$isDisabled()));
  }

  onSelectChange({ checked }: MatCheckboxChange) {
    this.selected.emit({
      checked,
      node: this.node(),
    });
  }

  isNodeSelected({ issue }: ItemScheduleTreeNode) {
    return this.selectedTicketIdList().find((issueId) => issueId === issue.id);
  }

  isDisabled(node: ItemScheduleTreeNode): boolean {
    const children = this.#getNodeChildren(node);
    const isTeamIssue = this.team() && node.issue.team?.id === this.team()!.id;
    const isIssueDisabled = !isIssueActive(node.issue);
    if (children.length) {
      return children.every((child) => this.isDisabled(child));
    }
    return isIssueDisabled || !isTeamIssue;
  }

  isDone(node: ItemScheduleTreeNode): boolean {
    if (isTicketDone(node.issue)) {
      return true;
    }
    const children = this.#getNodeChildren(node);
    if (!children.length) {
      return false;
    }
    return (
      children.every((node) => this.isDisabled(node)) &&
      children.some((node) => this.isDone(node))
    );
  }

  #getNodeChildren(node: ItemScheduleTreeNode) {
    return node.children || [];
  }
}
