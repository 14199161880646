import { booleanAttribute, Directive, effect, input } from '@angular/core';
import { useHostElement } from '@pwiz/infra/hooks';

@Directive({ selector: '[pwClickOnInit]', standalone: true })
export class ClickOnInitDirective {
  #element = useHostElement();
  shouldClick = input(true, {
    alias: 'pwClickOnInit',
    transform: booleanAttribute,
  });
  clickDelay = input<number | null, string | number>(null, {
    alias: 'pwClickOnInitDelay',
    transform: Number,
  });
  constructor() {
    effect((onCleanup) => {
      const delay = this.clickDelay();
      if (delay == null) {
        this.#click();
        return;
      }

      const timeout = setTimeout(() => {
        this.#click();
      }, delay);
      onCleanup(() => clearTimeout(timeout));
    });
  }

  #click() {
    if (!this.shouldClick()) {
      return;
    }
    this.#element.nativeElement.click();
  }
}
