<table>
  <tr>
    @if (isExpandable()) {
      <td class="toggle">
        <button
          mat-icon-button
          matTreeNodeToggle
          [pwClickOnInit]="!$isDisabled()"
          [pwRotate]="isExpanded()"
          [attr.aria-label]="'Toggle '"
        >
          <mat-icon> expand_more </mat-icon>
        </button>
      </td>
    }
    @if (!noSelect()) {
      <td class="checkbox">
        <mat-checkbox
          class="pw-small-checkbox"
          [indeterminate]="$isIndeterminate()"
          [checked]="$isChecked()"
          [disabled]="$isDisabled()"
          (change)="onSelectChange($event)"
        />
      </td>
    }
    <td class="type">{{ node().issue.tmsMetaData.originalType }}</td>
    <td class="issue-id">
      <pw-ticket-id [ticket]="node().issue" hideIcon />
    </td>
    <td class="title" [matTooltip]="node().issue.summery">
      {{ node().title }}
    </td>
    <td class="team">
      {{ node().team?.name }}
    </td>
    <td class="effort">{{ node().issue | pwSprintTicketEffort }} SP</td>
    <td class="status">
      {{ node().issue.status | pwEnumToReadableString }}
    </td>
    @if (
      node().endSprint && node().endSprint?.index !== node().startSprint?.index
    ) {
      <td class="sprint">
        {{ node().startSprint | pwSprintName: '-' }}
        -
        {{ node().endSprint | pwSprintName: '-' }}
      </td>
    } @else {
      <td class="sprint">{{ node().startSprint | pwSprintName: '-' }}</td>
    }
  </tr>
</table>
