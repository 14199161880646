import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  model,
} from '@angular/core';
import {
  getItemScheduleTreeNodeChildren,
  ItemScheduleTreeNode,
  ItemSprintSchedule,
  toItemScheduleTreeNode,
} from '@pwiz/sprint/ts';
import { PwCacheStatus } from '@pwiz/infra/cache/ts';
import {
  ClickOnInitDirective,
  RectangleSkeletonComponent,
  RotateDirective,
  StatusSwitchCaseComponent,
} from '@pwiz/infra/ui';
import {
  MatNestedTreeNode,
  MatTree,
  MatTreeNestedDataSource,
  MatTreeNode,
  MatTreeNodeDef,
  MatTreeNodeOutlet,
  MatTreeNodePadding,
  MatTreeNodeToggle,
} from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { ItemSprintScheduleNodeComponent } from '../node/item-sprint-schedule-node.component';
import { filterNotNull, removeArrayDuplications } from '@pwiz/infra/ts';
import { isIssueActive } from '@pwiz/ticket/ts';
import { ITeam } from '@pwiz/entity/ts';

@Component({
  selector: 'pw-item-sprint-schedule',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: 'item-sprint-schedule.component.scss',
  templateUrl: 'item-sprint-schedule.component.html',
  imports: [
    StatusSwitchCaseComponent,
    MatTree,
    MatTreeNode,
    MatIcon,
    MatNestedTreeNode,
    MatTreeNodeToggle,
    MatTreeNodeDef,
    MatIconButton,
    MatTreeNodeOutlet,
    MatTreeNodePadding,
    ItemSprintScheduleNodeComponent,
    RotateDirective,
    RectangleSkeletonComponent,
    ClickOnInitDirective,
  ],
})
export class ItemSprintScheduleComponent {
  itemSchedule = input.required<ItemSprintSchedule | null>();
  fetchStatus = input<PwCacheStatus>('success');
  noSelect = input(false, { transform: booleanAttribute });
  team = input.required<ITeam | null>();
  selectedTickets = model<string[]>([]);
  disableSelection = input<string[]>([]);
  canOrder = input(false, { transform: booleanAttribute });

  treeControl = new NestedTreeControl<ItemScheduleTreeNode>(
    (node) => node.children,
  );
  dataSource = new MatTreeNestedDataSource<ItemScheduleTreeNode>();

  $tree = computed<ItemScheduleTreeNode[] | null>(() => {
    const schedule = this.itemSchedule();
    if (!schedule) {
      return null;
    }
    if (schedule.initiative.length) {
      return this.#mapInitiative(schedule.initiative);
    }
    if (schedule.epic.length) {
      return this.#mapEpic(schedule.epic);
    }
    if (schedule.ticket.length) {
      return this.#mapTicket(schedule.ticket);
    }
    return null;
  });

  hasChild = (_: number, node: ItemScheduleTreeNode) =>
    !!node.children && node.children.length > 0;

  constructor() {
    effect(() => (this.dataSource.data = this.$tree() || []), {
      allowSignalWrites: true,
    });
  }

  #mapInitiative(
    initiativeList: ItemSprintSchedule['initiative'],
  ): ItemScheduleTreeNode[] {
    return initiativeList.map((initiative) => ({
      ...toItemScheduleTreeNode(
        initiative,
        initiative.startSprint,
        initiative.endSprint,
      ),
      children: this.#mapEpic(initiative.children),
    }));
  }
  #mapEpic(epicList: ItemSprintSchedule['epic']): ItemScheduleTreeNode[] {
    return epicList.map((epic) => ({
      ...toItemScheduleTreeNode(epic, epic.startSprint, epic.endSprint),
      children: this.#mapTicket(epic.children),
    }));
  }

  #mapTicket(ticketList: ItemSprintSchedule['ticket']): ItemScheduleTreeNode[] {
    return ticketList.map((ticket) =>
      toItemScheduleTreeNode(ticket, ticket.sprint, null),
    );
  }

  onNodeSelected({
    node,
    checked,
  }: {
    node: ItemScheduleTreeNode;
    checked: boolean;
  }) {
    if (checked) {
      this.#addSelected(node);
      return;
    }
    this.#removeSelected(node);
  }

  #getSelectableIssueIds(nodes?: ItemScheduleTreeNode[]) {
    if (!nodes) {
      return [];
    }
    return nodes
      .map(({ issue }) => (isIssueActive(issue) ? issue.id : null))
      .filter(filterNotNull);
  }

  #removeSelected(node: ItemScheduleTreeNode) {
    const childrenIds = this.#getSelectableIssueIds(
      getItemScheduleTreeNodeChildren(node),
    );
    this.selectedTickets.update((selectedIds) =>
      selectedIds.filter((id) => !childrenIds.includes(id)),
    );
  }

  #addSelected(node: ItemScheduleTreeNode) {
    const childrenIds = this.#getSelectableIssueIds(
      getItemScheduleTreeNodeChildren(node),
    );
    this.selectedTickets.update((selectedIds) =>
      removeArrayDuplications([...selectedIds, ...childrenIds]),
    );
    console.log(this.selectedTickets());
  }
}
