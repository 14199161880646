import { Pipe, PipeTransform } from '@angular/core';
import { getSprintName, SprintMini } from '@pwiz/sprint/ts';

@Pipe({
  name: 'pwSprintName',
  standalone: true,
})
export class SprintNamePipe implements PipeTransform {
  transform(sprint: SprintMini | null, fallback = 'Sprint') {
    if (!sprint) {
      return fallback;
    }
    return getSprintName(sprint);
  }
}
