import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ITeam } from '@pwiz/entity/ts';

@Component({
  selector: 'pw-sprint-schedule-filter',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-flex pw-gap-l pw-center-vr pw-padding pw-padding-block',
  },
  styles: `
    .mat-slide-toggle {
      display: block;
    }
  `,
  template: `
    <mat-slide-toggle (change)="filterTeam.emit($event.checked)">
      Only {{ team()?.name || 'my' }} team
    </mat-slide-toggle>
    <mat-slide-toggle (change)="filterActive.emit($event.checked)">
      Only active tickets (open / in progress)
    </mat-slide-toggle>
  `,
  imports: [MatSlideToggle],
})
export class SprintItemScheduleFilterComponent {
  team = input.required<ITeam | null>();
  filterTeam = output<boolean>();
  filterActive = output<boolean>();
}
