import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  model,
  signal,
} from '@angular/core';
import {
  filterSprintItemSchedule,
  ItemSprintSchedule,
  SprintItemScheduleFilter,
} from '@pwiz/sprint/ts';
import { ITeam } from '@pwiz/entity/ts';
import { ItemSprintScheduleComponent } from './component/schedule/item-sprint-schedule.component';
import { SprintItemScheduleFilterComponent } from './component/sprint-item-schedule-filter.component';
import { PwCacheData } from '@pwiz/infra/cache/ts';

@Component({
  selector: 'pw-sprint-item-schedule',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ItemSprintScheduleComponent, SprintItemScheduleFilterComponent],
  host: {
    class: 'pw-scroll-container',
  },
  template: `
    <pw-sprint-schedule-filter
      [team]="team()"
      (filterActive)="onFilterActiveTickets($event)"
      (filterTeam)="onFilterTeam($event)"
    />
    <div class="pw-scroll pw-padding pw-padding-block">
      <pw-item-sprint-schedule
        [itemSchedule]="$itemSchedule()"
        [fetchStatus]="itemSchedule().status"
        [team]="team()"
        [(selectedTickets)]="$selectedTicketIdList"
        [noSelect]="readonly()"
      />
    </div>
  `,
})
export class SprintItemScheduleComponent {
  itemSchedule = input.required<PwCacheData<ItemSprintSchedule>>();
  team = input.required<ITeam | null>();
  $filter = signal<SprintItemScheduleFilter>({
    team: false,
    active: false,
  });
  $selectedTicketIdList = model<string[]>([]);
  $itemSchedule = computed(() => {
    const schedule = this.itemSchedule().data;
    if (!schedule) {
      return null;
    }
    return filterSprintItemSchedule(schedule, this.$filter());
  });
  readonly = input(false, { transform: booleanAttribute });

  onFilterActiveTickets(filterActiveTickets: boolean) {
    this.$filter.update((filter) => ({
      ...filter,
      active: filterActiveTickets,
    }));
  }

  onFilterTeam(filterTeam: boolean) {
    this.$filter.update((filter) => ({ ...filter, team: filterTeam }));
  }
}
