<pw-status-switch-case [status]="fetchStatus()">
  <ng-container>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node"
                     [matTreeNodePadding]="1"
                     [matTreeNodePaddingIndent]="28">

        <pw-item-sprint-schedule-node
          [node]="node"
          [team]="team()"
          [selectedTicketIdList]="selectedTickets()"
          [noSelect]="noSelect()"
          (selected)="onNodeSelected($event)"
        />
      </mat-tree-node>
      <mat-nested-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        [matTreeNodePadding]="1"
        [matTreeNodePaddingIndent]="28"
      >
        <pw-item-sprint-schedule-node
          [node]="node"
          [selectedTicketIdList]="selectedTickets()"
          [noSelect]="noSelect()"
          [team]="team()"
          [isExpanded]="treeControl.isExpanded(node)"
          isExpandable
          (selected)="onNodeSelected($event)"
        />
        <div
          [class.example-tree-invisible]="!treeControl.isExpanded(node)"
          role="group"
        >
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </ng-container>

  <ng-container pwStatusLoading>
    @for (loader of [0, 0, 0, 0, 0]; let index = $index; track index) {
      <div class="pw-padding pw-padding-m">
        <pw-rectangle-skeleton heightUnits="4" />
      </div>
    }
  </ng-container>
</pw-status-switch-case>
